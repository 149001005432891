.why-us {
    background: whitesmoke;
    padding: 0;
}

#why-us-image {
    background-image: url("../../../assets/why-us.jpg");
}

.why-us .content {
    padding: 60px 100px 0 100px;
}

.why-us .content h3 {
    font-weight: 400;
    font-size: 34px;
}

.why-us .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
}

.why-us .content p {
    font-size: 15px;
    color: #959595;
}

.why-us .video-box {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 400px;
    position: relative;
}

.why-us .accordion-list {
    padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
    padding: 0;
    list-style: none;
}

.why-us .accordion-list li + li {
    margin-top: 15px;
}

.why-us .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
}

.why-us .accordion-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
}

.why-us .accordion-list span {
    color: #7e1820;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
}

.why-us .accordion-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.why-us .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
    display: none;
}

.why-us .accordion-list a.collapsed {
    color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
    color: #7e1820;
}

.why-us .accordion-list a.collapsed .icon-show {
    display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
    display: none;
}

.why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#7e1820 50%, rgba(124, 197, 118, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
}

.why-us .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.why-us .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(124, 197, 118, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
}

.why-us .play-btn:hover::after {
    border-left: 15px solid #7e1820;
    transform: scale(20);
}

.why-us .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
}

@media (max-width: 1024px) {
    .why-us .content, .why-us .accordion-list {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    .why-us .content {
        padding-top: 30px;
    }
    .why-us .accordion-list {
        padding-bottom: 30px;
    }
}

@-webkit-keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}
