.team {
    background: #fff;
    padding: 60px 0;
}

.team .member {
    margin-bottom: 20px;
    overflow: hidden;
}

.team .member .member-img {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.team .member .social {
    position: absolute;
    left: 0;
    bottom: -40px;
    right: 0;
    height: 40px;
    opacity: 0;
    transition: bottom ease-in-out 0.4s;
    text-align: center;
    background: rgba(126, 24, 33, 0.8);
}

.team .member .social a {
    transition: color 0.3s;
    color: #fff;
    margin: 0 10px;
    padding-top: 8px;
    display: inline-block;
}

.team .member .social a:hover {
    color: #ffffff;
}

.team .member .social i {
    font-size: 18px;
    margin: 0 2px;
}

.team .member .member-info h4 {
    font-weight: 700;
    margin: 15px 0 5px 0;
    font-size: 18px;
}

.team .member .member-info span {
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 15px;
    color: #7e1820;
}

.team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #888888;
}

.team .member:hover .social {
    bottom: 0;
    opacity: 1;
    transition: bottom ease-in-out 0.4s;
}
