#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: black url("preloader.svg") no-repeat center center;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
}

.back-to-top i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: #7e1820;
    color: #fff;
    transition: all 0.4s;
}

.back-to-top i:hover {
    background: #fff;
    color: #7e1820;
}

@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background-color: whitesmoke;
}

.section-title {
    text-align: center;
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
    padding-bottom: 0;
    color: #151515;
}

.section-title p {
    margin-bottom: 0;
    color: #aeaeae;
}
