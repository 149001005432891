#footer {
    background: url("../assets/footer-bg.jpg") center center no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 14px;
    position: relative;
}

#footer::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

#footer .footer-top {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 80px 0;
}

#footer .footer-top .footer-logo img {
    height: 80px;
}

#footer .footer-top h3 {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    position: relative;
    font-family: "Poppins", sans-serif;
    padding: 30px 0 0 0;
    margin-bottom: 0;
}

#footer .footer-top p {
    font-size: 15;
    font-style: italic;
    margin: 30px 0 0 0;
    padding: 0;
}

#footer .footer-top .social-links {
    margin-top: 30px;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #7e1820;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #ffffff;
    color: #7e1820;
    text-decoration: none;
}

#footer .footer-bottom {
    border-top: 1px solid #222222;
    z-index: 2;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
}

#footer .copyright {
    text-align: center;
}

#footer .credits {
    text-align: center;
    font-size: 13px;
    padding-top: 5px;
}
