.more-services img {
    border-radius: 0;
    max-height: 200px;
}

.more-services .card {
    border: 0;
    text-align: center;
}

.more-services .card-body {
    -moz-text-align-last: center;
    text-align-last: center;
}

.more-services .card-title a {
    font-weight: 600;
    font-size: 18px;
    color: #334240;
    transition: ease-in-out 0.3s;
}

.more-services .card-title a:hover {
    color: #7e1820;
}

.more-services .btn {
    border-radius: 50px;
    padding: 4px 30px 6px 30px;
    border: 2px solid #7e1820;
}

.more-services .btn:hover {
    color: #fff;
    background: #7e1820;
}
