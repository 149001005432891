body {
    font-family: "Open Sans", sans-serif;
    color: #555555;
}

a {
    color: #7e1820;
}

a:hover {
    color: #ffffff;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
}
