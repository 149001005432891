.contact .info {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
}

.contact .info i {
    font-size: 20px;
    color: #7e1820;
    float: left;
    width: 44px;
    height: 44px;
    background: #f5dde1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #151515;
}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #484848;
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #7e1820;
    color: #ffffff;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
