#hero {
    width: 100%;
    height: 100vh;
    background: url("../../../assets/hero-bg.jpg") no-repeat center;
    background-size: cover;
    position: relative;
    color: #fff;
}

#hero .hero-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 15px;
}

#hero .hero-logo {
    margin-bottom: 30px;
}

#hero .hero-logo img {
    max-height: 70px;
}

#hero h1 {
    margin: 0 0 30px 0;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    color: #fff;
}

#hero h2 {
    color: #fff6f6;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

#hero .btn-get-started {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 14px 30px;
    border-radius: 3px;
    transition: 0.5s;
    margin: 10px;
    color: #fff;
    background: #7e1820;
}

#hero .btn-get-started:hover {
    background: #fff;
    color: #7e1820;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        line-height: 22px;
    }
}
