.about .image {
    padding: 20px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-bottom: 10px;
    display: flex;
    align-items: flex-start;
}

.about .content ul i {
    font-size: 24px;
    padding: 2px 6px 0 0;
    color: #7e1820;
}

.about .content p:last-child {
    margin-bottom: 0;
}
